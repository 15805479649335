









'use strict';

var InteractionManager={
current:{
createInteractionHandle:function createInteractionHandle(){},
clearInteractionHandle:function clearInteractionHandle(){}},

inject:function inject(manager){
InteractionManager.current=manager;
}};


module.exports=InteractionManager;