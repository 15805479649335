









'use strict';

var RequestAnimationFrame={
current:function current(cb){return global.requestAnimationFrame(cb);},
inject:function inject(injected){
RequestAnimationFrame.current=injected;
}};


module.exports=RequestAnimationFrame;