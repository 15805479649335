









'use strict';

var CancelAnimationFrame={
current:function current(id){return global.cancelAnimationFrame(id);},
inject:function inject(injected){
CancelAnimationFrame.current=injected;
}};


module.exports=CancelAnimationFrame;