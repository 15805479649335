









'use strict';

var _uniqueId=0;

module.exports=function uniqueId(){
return String(_uniqueId++);
};