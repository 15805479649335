









'use strict';var _extends=Object.assign||function(target){for(var i=1;i<arguments.length;i++){var source=arguments[i];for(var key in source){if(Object.prototype.hasOwnProperty.call(source,key)){target[key]=source[key];}}}return target;};

var invariant=require('invariant');

var Animated=require('./Animated');
var AnimatedValue=require('./AnimatedValue');
var AnimatedValueXY=require('./AnimatedValueXY');
var AnimatedAddition=require('./AnimatedAddition');
var AnimatedMultiplication=require('./AnimatedMultiplication');
var AnimatedModulo=require('./AnimatedModulo');
var AnimatedTemplate=require('./AnimatedTemplate');
var AnimatedTracking=require('./AnimatedTracking');
var isAnimated=require('./isAnimated');

var Animation=require('./Animation');
var TimingAnimation=require('./TimingAnimation');
var DecayAnimation=require('./DecayAnimation');
var SpringAnimation=require('./SpringAnimation');

































var maybeVectorAnim=function maybeVectorAnim(
value,
config,
anim)
{
if(value instanceof AnimatedValueXY){
var configX=_extends({},config);
var configY=_extends({},config);
for(var key in config){var _config$key=
config[key],x=_config$key.x,y=_config$key.y;
if(x!==undefined&&y!==undefined){
configX[key]=x;
configY[key]=y;
}
}
var aX=anim(value.x,configX);
var aY=anim(value.y,configY);


return parallel([aX,aY],{stopTogether:false});
}
return null;
};

var spring=function spring(
value,
config)
{
return maybeVectorAnim(value,config,spring)||{
start:function start(callback){
var singleValue=value;
var singleConfig=config;
singleValue.stopTracking();
if(config.toValue instanceof Animated){
singleValue.track(new AnimatedTracking(
singleValue,
config.toValue,
SpringAnimation,
singleConfig,
callback));

}else{
singleValue.animate(new SpringAnimation(singleConfig),callback);
}
},

stop:function stop(){
value.stopAnimation();
}};

};

var timing=function timing(
value,
config)
{
return maybeVectorAnim(value,config,timing)||{
start:function start(callback){
var singleValue=value;
var singleConfig=config;
singleValue.stopTracking();
if(config.toValue instanceof Animated){
singleValue.track(new AnimatedTracking(
singleValue,
config.toValue,
TimingAnimation,
singleConfig,
callback));

}else{
singleValue.animate(new TimingAnimation(singleConfig),callback);
}
},

stop:function stop(){
value.stopAnimation();
}};

};

var decay=function decay(
value,
config)
{
return maybeVectorAnim(value,config,decay)||{
start:function start(callback){
var singleValue=value;
var singleConfig=config;
singleValue.stopTracking();
singleValue.animate(new DecayAnimation(singleConfig),callback);
},

stop:function stop(){
value.stopAnimation();
}};

};

var sequence=function sequence(
animations)
{
var current=0;
return{
start:function start(callback){
var onComplete=function onComplete(result){
if(!result.finished){
callback&&callback(result);
return;
}

current++;

if(current===animations.length){
callback&&callback(result);
return;
}

animations[current].start(onComplete);
};

if(animations.length===0){
callback&&callback({finished:true});
}else{
animations[current].start(onComplete);
}
},

stop:function stop(){
if(current<animations.length){
animations[current].stop();
}
}};

};




var parallel=function parallel(
animations,
config)
{
var doneCount=0;

var hasEnded={};
var stopTogether=!(config&&config.stopTogether===false);

var result={
start:function start(callback){
if(doneCount===animations.length){
callback&&callback({finished:true});
return;
}

animations.forEach(function(animation,idx){
var cb=function cb(endResult){
hasEnded[idx]=true;
doneCount++;
if(doneCount===animations.length){
doneCount=0;
callback&&callback(endResult);
return;
}

if(!endResult.finished&&stopTogether){
result.stop();
}
};

if(!animation){
cb({finished:true});
}else{
animation.start(cb);
}
});
},

stop:function stop(){
animations.forEach(function(animation,idx){
!hasEnded[idx]&&animation.stop();
hasEnded[idx]=true;
});
}};


return result;
};

var delay=function delay(time){

return timing(new AnimatedValue(0),{toValue:0,delay:time,duration:0});
};

var stagger=function stagger(
time,
animations)
{
return parallel(animations.map(function(animation,i){
return sequence([
delay(time*i),
animation]);

}));
};




var event=function event(
argMapping,
config)
{
return function(){for(var _len=arguments.length,args=Array(_len),_key=0;_key<_len;_key++){args[_key]=arguments[_key];}
var traverse=function traverse(recMapping,recEvt,key){
if(typeof recEvt==='number'){
invariant(
recMapping instanceof AnimatedValue,
'Bad mapping of type '+typeof recMapping+' for key '+key+
', event value must map to AnimatedValue');

recMapping.setValue(recEvt);
return;
}
invariant(
typeof recMapping==='object',
'Bad mapping of type '+typeof recMapping+' for key '+key);

invariant(
typeof recEvt==='object',
'Bad event of type '+typeof recEvt+' for key '+key);

for(var key in recMapping){
traverse(recMapping[key],recEvt[key],key);
}
};
argMapping.forEach(function(mapping,idx){
traverse(mapping,args[idx],'arg'+idx);
});
if(config&&config.listener){
config.listener.apply(null,args);
}
};
};


























































































module.exports={




Value:AnimatedValue,



ValueXY:AnimatedValueXY,





decay:decay,




timing:timing,




spring:spring,





add:function add(a,b){
return new AnimatedAddition(a,b);
},




multiply:function multiply(a,b){
return new AnimatedMultiplication(a,b);
},





modulo:function modulo(a,modulus){
return new AnimatedModulo(a,modulus);
},





template:function template(strings){for(var _len2=arguments.length,values=Array(_len2>1?_len2-1:0),_key2=1;_key2<_len2;_key2++){values[_key2-1]=arguments[_key2];}
return new AnimatedTemplate(strings,values);
},




delay:delay,





sequence:sequence,





parallel:parallel,




stagger:stagger,

















event:event,





isAnimated:isAnimated,




createAnimatedComponent:require('./createAnimatedComponent'),

inject:{
ApplyAnimatedValues:require('./injectable/ApplyAnimatedValues').inject,
InteractionManager:require('./injectable/InteractionManager').inject,
FlattenStyle:require('./injectable/FlattenStyle').inject,
RequestAnimationFrame:require('./injectable/RequestAnimationFrame').inject,
CancelAnimationFrame:require('./injectable/CancelAnimationFrame').inject},


__PropsOnlyForTests:require('./AnimatedProps')};